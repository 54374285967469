import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../modules/layout/Layout'
import Stage from '../modules/stage/Stage'
import ReactMarkdown from 'react-markdown'

export const ImpressumPageTemplate = ({
  title,
  content,
  image,
}) => {

  return (
    <div>
      <div className={'wrapper'}>
        <Stage title={title} image={image} hideButtons={true} />
        <section className='main'>
          <div className={'content'}>
            <ReactMarkdown children={content} className={'markup-content'} />
          </div>
        </section>
      </div>
    </div >
  )
}

ImpressumPageTemplate.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const ImpressumPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      seo_title={post.frontmatter.seo_title}
      seo_description={post.frontmatter.seo_description}
      seo_image={post.frontmatter.image}>
      <ImpressumPageTemplate
        content={post.frontmatter.content}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

ImpressumPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ImpressumPage

export const pageQuery = graphql`
  query ImpressumPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "impressum-page" } }) {
      id
      html
      frontmatter {
        title
        content
        seo_title
        seo_description
        image {
          childImageSharp {
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
      }
    }
  }
`
